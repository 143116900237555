@import "assets/stylesheet/variables.scss";
.hidden {
  display: none !important;
}
.navbar-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  height: 66px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  justify-content: space-between;
  .hamburger-icon {
    display: none;
    @media (max-width: 1023px) {
      font-size: 30px;
      display: block;
    }
  }

  .navbar-left {
    height: 100%;
    align-items: center;

    .navbar-links-container {
      height: 100%;
      align-items: center;
      // margin-left: 42px;
      // justify-content: flex-end;
      width: 100%;
      &.group-link {
        width: auto !important;
        border-right: 2px solid #e0e0e0;
        height: 40px;
      }
      &.group-link-3 {
        width: auto !important;
      }
      @media (max-width: 1023px) {
        display: none;
      }
      a {
        // margin-right: 10px;
        // margin-top: 23px;
        // height: 100%;
        padding: 10px 10px 9px 10px;
        font-size: medium;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        color: #e5c4ff;
      }
      .display-menu {
        display: block;
      }
      .active {
        color: #9134d8;
        // border: 1px solid #a05dd3;
        background-color: #f5eafb;
        // &.active-border{
        //   border-bottom: 2px solid #a05dd3;
        // }
        &::after {
          content: "";
          background: -webkit-linear-gradient(left, #a05dd3, #a05dd3);
          display: block;
          height: 3px;
          width: 100%;
          position: absolute;
          bottom: -2px;
          border-radius: $theme-border-radius;
          left: 0;
        }
      }

      .navbar-link {
        // padding-right: 15px;
        font-size: 14px;
        text-align: center;
        .navbar-links-icon {
          margin-bottom: -2px;
          width: 16px;
          &.news {
            filter: brightness(0.5) saturate(5);
          }
        }

        &.active {
          img {
            opacity: 1;
          }
        }

        img {
          opacity: 0.2;
        }
      }
    }
  }

  .user-data {
    display: flex;
    align-items: center;
    padding: 10px;
    .user-pill {
      border-radius: 20px;
      box-shadow: 0 2px 5px 0 rgba(29, 14, 57, 0.24);
      display: flex;
      align-items: center;
      // padding: 3px 6px;
      // margin-right: 12px;
      cursor: pointer;
      @media (max-width: 1023px) {
        margin-right: 0px;
        box-shadow: 0 2px 5px 0 rgba(29, 14, 57, 0.24);
      }

      .user-name {
        min-width: 140px;
        color: $theme-color;
        font-weight: 500;
        font-size: 14px;
        @media (max-width: 1023px) {
          display: none;
        }
      }

      .parent-text {
        font-size: 10px;
        color: $theme-color;
      }
    }

    .display-picture {
      vertical-align: middle;
      // margin-right: 16px;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: #e5c4ff;
      @media (max-width: 1023px) {
        margin-right: 0px;
      }
    }

    .swap-icon {
      width: 40px;
      height: 40px;
      background-color: $theme-color;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  .ant-select {
    div {
      border: none;
    }
  }

  // .border-none {
  //   border: none;
  // }
  @media (max-width: 1023px) {
    .user-data {
      // width: 100%;
      justify-content: inherit;

      .user-pill {
        justify-content: inherit;
      }
    }
    //.logo {
    // display: none;
    //}
  }

  .navbar-overlay {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;

    & .close-btn {
      position: absolute;
      top: 20px;
      right: 45px;
      font-size: 60px;
      color: #818181;
      cursor: pointer;

      &:hover {
        color: #f1f1f1;
      }
    }

    .overlay-content {
      position: relative;
      top: 25%;
      width: 100%;
      text-align: center;
      margin-top: 30px;

      .navbar-links-container {
        flex-direction: column;
        height: 100%;

        a {
          position: relative;
          margin-bottom: 15px;
          color: #818181;

          &:hover {
            color: #f1f1f1;
          }
        }

        .navbar-link {
          // padding-right: 15px;
          font-size: 30px;

          .navbar-links-icon {
            margin-bottom: -2px;
          }
        }
      }
    }
  }
}

.sub-header {
  position: fixed;
  left: 0;
  top: 67px;
  right: 0;
  width: 100%;
  z-index: 100;
  height: 66px;
  display: flex;
  align-items: center;
  padding: 0 22px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
}
// Outside because the element isn't mounted inside the
.child-list-container {
  color: #000000;
  background: #fff;
  margin-top: 6px;
  //border-radius: 25px;
  font-size: 12px;
  box-shadow: 0px 2px 10px 2px #0000005c;
  user-select: none;
}
.no-margin {
  width: 80%;
  margin: auto;
}
.child-list-item {
  padding: 5px 10px;
  //padding: 5px 20px;
  //padding-top: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 160px;
  justify-content: space-between;
  cursor: pointer;
  img {
    width: 15px;
  }
  .child-name {
    display: flex;
    justify-content: center;
    align-items: center;
    .name-trunc {
      white-space: nowrap;
      width: 90px;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
    .grade-text {
      font-size: 8px;
      color: grey;
    }
  }
  .child-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
    background: #e5c4ff;
  }
  .menu-image {
    height: 22px;
    width: 22px;
    padding: 6px;
  }
  .plus-menu-image {
    height: 22px;
    width: 22px;
    padding: 6px;
  }
  .icon-container {
    border-radius: 50%;
    height: 22px;
    width: 22px;
    margin-right: 16px;
    background: #eee;
  }
  .add-profile-icon {
    border-radius: 50%;
    height: 22px;
    width: 22px;
    margin-right: 16px;
    background: #eee;
  }
}

.level-select {
  display: flex;
  border: 0.5px solid #c7b3b3;
  height: 36px;
  border-radius: $theme-border-radius;
  padding: 5px;
  width: 128px;
  //margin: 10px;
  margin: 2px;
  margin-right: 10px;
  justify-content: space-around;
}

.search-box {
  width: 350px !important;
  @media screen and (max-width: 900px) {
    width: auto !important;
    position: relative !important;
  }
}
.story-modal {
  width: 60vw !important;
  height: 85vh !important;
  @media screen and (max-width: 1023px) {
    width: 100vw !important;
    height: 100vh !important;
    margin: 0;
    padding: 0;
  }
  .ant-modal-content {
    height: 100%;
    background: transparent !important;
    .ant-modal-close-x {
      color: #212121;
      svg {
        height: 20px;
        width: 20px;
      }
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }
}
.ant-modal-body {
  padding: 0px !important;
  height: 100%;
}

.success-modal {
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
}

.ant-dropdown {
  position: fixed !important;
}
.btn-cat {
  color: #9134d8;
  background-color: #f2f2f2;
  border-radius: $theme-border-radius;
  border: none;
  padding: 2px 1rem;
  margin-right: 0.75rem;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
  }
}
.btn-cat.actives {
  color: #f2f2f2;
  background-color: #9134d8;
}
.btn-teacher {
  font-size: 20px;
  border: none;
  background-color: white;
  opacity: 0.4;
  font-weight: 500;
  padding: 5px 10px;
  margin-right: 0.75rem;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
  }
}
.btn-teacher.actives {
  color: rgb(40, 16, 69);
  border-bottom: 2px solid rgb(255, 158, 49);
  opacity: 1;
}
.Srch_main {
  // background-color: white;
  position: fixed;
  //FREAD-1213
  // top: 66.25px;
  // width: 100%;
  //border: solid lightgrey;
  //FREAD-1213
  // z-index: 99;
}
.Srch_Teacher {
  background-color: white;
  position: fixed;
  top: 67px;
  width: 50%;
  //border: solid lightgrey;
  //FREAD-1213
  z-index: 99;
}
.filter-relate {
  float: right;
  position: relative;
  margin-right: 535px;
  //width: 350px;
  width: 38.75%;
  margin-top: 6px;
  @media screen and (max-width: 1023px) {
    margin-right: unset;
    width: 100%;
  }
}
.teacher-no-data {
  font-size: 20px;
  font-weight: 400;
  background-color: white;
  padding: 10px;
  text-align: center;
  color: rgb(0, 116, 217);
}
.teacher-absolute {
  position: absolute;
  width: 100%;
  background-color: white;
  //FREAD-1213
  z-index: 99;
  border: 1px solid rgb(204, 204, 204);
}
.filter-absolute {
  position: absolute;
  width: 100%;
  background-color: white;
  border-radius: $theme-border-radius;
  padding: 1rem 0.65rem;
  //FREAD-1213
  z-index: 99;
}
.ant-input {
  border-radius: $theme-border-radius;
}
.ant-input:focus,
.ant-input:hover,
span.ant-input-affix-wrapper:hover,
span.ant-input-suffix:hover,
span.ant-input-affix-wrapper,
span.ant-input-suffix,
.ant-input {
  border-color: #9134d8 !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}

.list {
  display: flex;
  flex-direction: column;
}
.list_img {
  flex: 0 1 20%;
  img {
    width: 100%;
  }
}
.list_name {
  flex: 0 1 80%;
}
.ant-avatar {
  border-radius: $theme-border-radius;
}
.ant-list-item-meta-title {
  margin-top: 5px;
  margin-bottom: 5px;
}
.react-dropdown-select,
.css-wmw4vi-ReactDropdownSelect {
  border-color: #9134d8 !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  //width: 473px !important;
  //width: 350px !important;
  width: 38.75% !important;
  float: right !important;
  margin-right: 540px;
  border-radius: $theme-border-radius !important;
  @media screen and (max-width: 1023px) {
    width: calc(100%) !important;
    position: relative !important;
    margin-right: unset !important;
  }
}
.react-dropdown-select:focus,
.css-wmw4vi-ReactDropdownSelect:focus,
.react-dropdown-select:hover,
.css-wmw4vi-ReactDropdownSelect:hover {
  border-color: #9134d8 !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}
.srch_cat {
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: 1rem;
  .btn-cat {
    margin-bottom: 1%;
  }
}
.teacher_cat {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 3px 0px;
}
.anticon {
  line-height: unset;
}
.list_flow {
  max-height: 220px;
  overflow: auto;
}
.ant-list-item {
  cursor: pointer;
}
.input-search {
  float: right;
  //width: 350px;
  width: 38.75%;
  margin-right: 535px;
  margin-bottom: 2px;
  margin-top: 2px;
  height: 36px;
  padding-right: 5px;
  padding-left: 5px;
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-right: unset;
  }
}
.search-anchors {
  @media screen and (max-width: 1023px) {
    display: none;
  }
}
.css-zdyb4r-DropDown {
  @media screen and (max-width: 1023px) {
    width: 100% !important;
  }
}
.ant-input-clear-icon,
.css-wmw4vi-ReactDropdownSelect * {
  font-size: 20px;
}
.search-mobile {
  display: none;
  background-color: #f2f2f2;
  border-radius: 50%;
  color: #9134d8;
  font-weight: bolder;
  width: 30px;
  height:30px;
  @media screen and (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: center;
    // i {
    //   margin: 2px 6px !important;
    // }
  }
}
i.ant-spin-dot-item {
  background-color: #9134d8;
}

.navbar-user-dropdown-menu{
  max-height: 92%;
  overflow-y: auto;
}

.navbar-user-dropdown-menu.ant-dropdown::before{
  position: relative !important;
}
