@import "assets/stylesheet/variables.scss";

.homework-button {
  @media screen and (min-width: 1137px) {
    margin-left: 35% !important;
  }
  @media screen and (min-width: 715px) {
    margin-left: 20%;
  }
}

.primary-btn {
  @media screen and (max-width: 715px) {
    font-size: 10px !important;
    margin-left: 20%;
  }
  @media screen and (max-width: 462px) {
    font-size: 9px !important;
    margin-right: 15% !important;
  }
}

// .ant-btn {
//   margin-top: 20px !important;
// }

.announcement-modal {
  margin: 10px 0;
  height: 90%;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: "Rubik";
  @media screen and (min-width: 1137px) {
    width: 80% !important;
  }
  @media screen and (min-width: 715px) {
    width: 88%;
  }
  // @media screen and (min-width: 462px) {
  //   width: 88%;
  // }

  .ant-modal {
    @media screen and (max-width: 460px) {
      width: 100% !important;
      max-width: unset;
    }
  }

  @media screen and (max-width: 460px) {
    overflow-y: hidden;
    height: 100%;
    /*::-webkit-scrollbar {
      //display: none;
    }*/
  }

  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .d-flex {
    display: flex;
    flex-direction: row;
    //align-items: center;

    &.reverse {
      flex-direction: column;
    }
  }

  .listing-row {
    &-profile-pic {
      height: 60px;
      width: 60px;
      border-radius: 30px;
      background: #979797;
      display: inline-block;
      margin: 0;
      overflow: hidden;
      vertical-align: middle;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      img {
        height: 100%;
      }
    }
  }

  .j-c-c {
    justify-content: center;
  }

  .j-c-sb {
    justify-content: space-between;
  }

  .j-c-se {
    justify-content: space-evenly;
  }

  .j-c-fs {
    justify-content: flex-start;
  }

  .a-i-fs {
    align-items: flex-start;
  }

  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 25px 15px;
    border: none;
    @media screen and (max-width: 460px) {
      border-bottom: 1px solid #828a9d;
    }

    .ant-modal-title {
      color: #524e59;
      font-weight: 500;
      font-family: "Rubik";
      font-size: 28px;
      @media screen and (max-width: 460px) {
        font-size: 24px;
      }
    }
  }

  .ant-modal-content {
    // background-color: white !important;
    color: #7d7a83;
    overflow-y: auto;
    height: 75vh;
    @media screen and (min-width: 1137px) {
      width: 180% !important;
    }
    @media screen and (min-width: 715px) {
      width: 140%;
    }

    @media screen and (max-width: 460px) {
      overflow-y: auto;
      height: 85vh;
      padding-bottom: 10px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }

    .ant-modal-body {
      padding: 0px 15px 18px 15px !important;
      @media screen and (max-width: 460px) {
        padding: 0px 0 25px 0 !important;
      }

      .children-cards {
        flex-wrap: wrap;

        .no-data-label {
          font-size: 18px;
          padding: 0 0 0 9px;
        }

        .children-card {
          margin-right: 10px;
          margin-bottom: 10px;
          width: 280px;
          padding: 3px 30px;
          border-radius: 15px;
          //border: 1px solid rgba(151, 151, 151, 0.3);
          margin-left: 3px;

          @media screen and (max-width: 460px) {
            width: 100%;
          }

          h2 {
            color: #524e59;
            font-size: 20px;
          }

          .wow-pic {
            img {
              width: 60px;
              object-fit: contain;
              height: 50px;
            }
          }

          .purple-text {
            color: $magenta-color;
            font-weight: 500;
            font-size: 18px;

            ~ p {
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px;
    padding-bottom: 60px;
    display: none;
  }

  .ant-modal-close {
    display: inline;

    .anticon {
      padding: 9px;
      background: #ececec;
      border-radius: 100px;
    }
  }

  .ant-modal-close-x {
    position: absolute;
    right: 7px;
    top: 5px;
  }

  .children-cards {
    flex-wrap: wrap;

    .no-data-label {
      font-size: 18px;
      padding: 0 0 0 9px;
    }

    .children-card {
      margin-right: 10px;
      margin-bottom: 10px;
      width: 280px;
      padding: 3px 30px;
      border-radius: 15px;
      border: 1px solid rgba(151, 151, 151, 0.3);
    }
  }

  .post-heading {
    width: 100%;
    margin: 7px 0;
    border-bottom: 1px solid #d8d8d8;
    @media screen and (max-width: 460px) {
      width: 100%;
      border-bottom: 1px solid #828a9d !important;
      margin: unset;
    }

    .announcement-content {
      @media screen and (max-width: 460px) {
        padding: 14px 15px;
      }

      .desc {
        color: #86838b;
        font-size: 11px;
        text-align: left;
        word-wrap: break-word;
        padding-bottom: 5px;
      }
    }
  }

  .post-heading:last-of-type {
    border-bottom: unset;
  }

  .media {
    align-items: center;

    img {
      margin: 0 40px 0 11px !important;
    }
  }

  .media-body {
    padding-left: 20px;

    p {
      color: #524e59;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }

    .announcement-date {
      color: #524e59;
      font-size: 12px;
      font-weight: 400;
      text-align: right;
      opacity: 0.5;
    }

    h4 {
      color: #524e59;
      font-size: 14px;
      font-weight: 500;

      &.announcement-heading {
        font-weight: bold;
      }
    }

    .anchor-time {
      color: #524e59;
      opacity: 0.5;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    &.mediaBody {
      padding-left: 8px;
    }
  }

  .d-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .j-c-sb {
    justify-content: space-between !important;
  }

  .event-btn {
    text-transform: uppercase;
    background-color: rgba(251, 131, 0, 0.16);
    color: #f4712b;
    font-size: 10px;
    margin-bottom: 5px;
    padding: 3px 8px;
    font-weight: 500;
    display: block;
    border-radius: 4px;
    border: none;
    outline: none;

    &.holiday-btn {
      background-color: rgba(31, 185, 219, 0.16);
      color: #1fb9db;
    }
  }

  .announcement-date {
    font-size: 9px;
    color: #524e59;
    font-size: 12px;
    font-weight: 400;
    text-align: right;
  }

  .post-body {
    .post-text {
      padding-top: 9px;
      font-size: 12px;
      font-weight: 400;
      color: #524e59;
      opacity: 0.7;
      line-height: 16px;
      text-align: left;
      position: relative;
      margin-bottom: 0;
    }

    &.postBodyRight {
      position: relative;
      //border-bottom: 2px solid rgba(129, 137, 156, 0.2);
    }
  }
}

.announcement-modal {
  .ant-modal-body {
    padding: 0px !important;
  }
}
